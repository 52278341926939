import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { theme } from '../../../theme/theme'
import { useSelectScreening, useSelectScreeningResultStatus } from '../../../state/selectors'
import { ScreeningResultStatus } from '../../../api/ester-b2b-api/private/screeningResult/types'
import { Arrow, Close, ErrorSign } from '../../../components/icons'
import { OnClickButton } from '../../../components/buttons/onclick/OnClickButton'
import { Stack } from '@mui/material'
import styled from 'styled-components'
import { BodyLarge, BodyMedium, Title3, Title4 } from '../../../components/styles/Typography'
import { useWindowWidth } from '../../../hooks/useWindowWidth'
import { isLowerThenCustomBreakpoint } from '../../../utils/devices'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Checkbox } from '../../../components/Checkbox'
import { useConsentMutation } from '../../../api/ester-b2b-api/private/consent/consentQueries'
import { ConsentId } from '../../../api/ester-b2b-api/private/consent/types'
import {
  useFetchNextQuestionMutation,
  useScreeningtMutation,
} from '../../../api/ester-b2b-api/private/screening/screeningQueries'
import { ScreeningType } from '../../../api/ester-b2b-api/private/screening/types'
import { useScreeningQuestionnaireStore } from '../../../state/store'
import { queryErrorHandler } from '../../../utils/queryErrorHandler'

const screeningTexts = {
  handled: {
    label: 'Din screening är klar',
    title: 'Din screening är klar',
    description: 'Du hittar din personliga hälsoplan under "Din hälsa". Där kan du se din analys och hälsoplan.',
  },
  completed: {
    label: 'Din screening hanteras',
    title: 'Du har genomfört din screening',
    description: 'Dina resultat färdigställs inom 3 vardagar och du får återkoppling via mail.',
  },
  started: {
    label: 'Fortsätt med din screening',
    title: 'Screeningtjänst för kvinnohälsa',
    description:
      'Gör klart din screening för att få din personliga hälsoplan. Upplever du besvär du tror är kopplade till din hormonella och gynekologiska hälsa? Eller vill du kanske se om du kan optimera din kvinnohälsa på något sätt? Gör vår digitala screening!',
  },
  unstarted: {
    label: 'Starta screeningen',
    title: 'Screeningtjänst för kvinnohälsa',
    description:
      'Upplever du besvär du tror är kopplade till din hormonella och gynekologiska hälsa? Eller vill du kanske se om du kan optimera din kvinnohälsa på något sätt? Gör vår digitala screening!',
  },
}

export const Screening2 = () => {
  const navigate = useNavigate()
  const width = useWindowWidth()
  const isTabletOrMobile = isLowerThenCustomBreakpoint(width, 769)
  const iconSize = isTabletOrMobile ? 24 : 32
  const [consent, setConsent] = useState(false)
  const [isError, setIsError] = useState(false)

  const selectedScreening = useSelectScreening()
  const selectedScreeningResultStatus = useSelectScreeningResultStatus()
  const isScreeningHandled = selectedScreeningResultStatus === ScreeningResultStatus.HANDLED
  const isScreeningCompleted = selectedScreening?.completed
  const isScreeningStarted = selectedScreening && !selectedScreening.completed

  const { mutate: setUserConsent } = useConsentMutation()
  const { mutate: getNextQuestion } = useFetchNextQuestionMutation()
  const { mutate: postStartScreening, isPending: isStartPending } = useScreeningtMutation()
  const { setCurrentQuestion } = useScreeningQuestionnaireStore()

  const getScreeningText = () => {
    if (isScreeningHandled) return screeningTexts.handled
    else if (isScreeningCompleted) return screeningTexts.completed
    else if (isScreeningStarted) return screeningTexts.started
    else return screeningTexts.unstarted
  }

  const handleCloseClick = () => {
    navigate('/portal/halsotjanster')
  }

  const handleNextClick = () => {
    if (consent && !isScreeningStarted) {
      setUserConsent(ConsentId.SCREENING_CONSENT, {
        onError: (error) => {
          setIsError(true)
          queryErrorHandler('set user consent')(error)
        },
      })

      postStartScreening(ScreeningType.STANDARD, {
        onSuccess: (screeningData) => {
          if (screeningData?.id) {
            getNextQuestion(screeningData.id, {
              onSuccess: (newQuestion) => {
                setCurrentQuestion(newQuestion)
              },
            })
            navigate('/portal/halsotjanster/screening2/questions')
          }
        },
        onError: (error) => {
          setIsError(true)
          queryErrorHandler('post start screening')(error)
        },
      })
    }
    if (isScreeningStarted && selectedScreening.id) {
      getNextQuestion(selectedScreening.id, {
        onSuccess: (data) => {
          setCurrentQuestion(data)
          navigate('/portal/halsotjanster/screening2/questions')
        },
        onError: (error) => {
          setIsError(true)
          queryErrorHandler('get next question')(error)
        },
      })
    }
  }

  return (
    <>
      <HeaderContainer>
        <Section flexDirection="row" justifyContent="space-between">
          <Spacing />
          <Title4>{getScreeningText().label}</Title4>
          <Close size={iconSize} onClick={handleCloseClick} color={theme.color.plum} />
        </Section>
      </HeaderContainer>
      <ComponentLayout
        noMaxWidth
        paddingTop={isTabletOrMobile ? theme.spacing.xsmall : theme.spacing.xxlarge}
        style={{
          height: '100%',
        }}
      >
        <Section justifyContent="space-between">
          <ContentConatiner>
            <TextContainer>
              <StyledTitle3>{getScreeningText().title}</StyledTitle3>
              <BodyLarge>{getScreeningText().description}</BodyLarge>
            </TextContainer>
            <ConsentContainer>
              {!isScreeningStarted && (
                <>
                  <Checkbox
                    onChange={() => setConsent(!consent)}
                    checked={consent}
                    borderColor={theme.color.grey3}
                    backgroundColor={theme.color.white}
                  />
                  <ConsentTextContainer>
                    <BodyMedium>
                      Ja, jag samtycker till att mina svar används för att ta fram min hälsoplan. Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </BodyMedium>
                    <Link to="https://estercare.com/privacy-policy">
                      Läs mer om hur vi hanterar dina personuppgifter
                    </Link>
                  </ConsentTextContainer>
                </>
              )}
            </ConsentContainer>
            {isError && (
              <ErrorContainer>
                <ErrorSign color={theme.color.red} size={28} />
                <BodyLarge>
                  Det gick inte att starta screeningen, försök igen eller kontakta support@estercare.com
                </BodyLarge>
              </ErrorContainer>
            )}
          </ContentConatiner>

          <ButtonContainer $hideButton={isScreeningCompleted || isScreeningCompleted}>
            <OnClickButton
              text={getScreeningText().label}
              onClick={handleNextClick}
              loading={isStartPending}
              icon={<Arrow color={theme.color.white} size={15} />}
              disabled={!consent && !isScreeningStarted} //TODO: check if the answer is valid
            />
          </ButtonContainer>
        </Section>
      </ComponentLayout>
    </>
  )
}

const HeaderContainer = styled.div`
  padding: ${theme.spacing.large}px ${theme.spacing.medium}px;

  & svg {
    cursor: pointer;
  }

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    border-bottom: 1px solid ${theme.color.beigeDark};
  }
`
const Section = styled(Stack)`
  max-width: 600px;
  margin: 0 auto;

  height: 100%;
`
const ContentConatiner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing.xxlarge}px;
`
const ConsentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  text-align: center;
`
const ConsentTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.tiny}px;
  margin-left: ${theme.spacing.small}px;
  text-align: left;
  & a {
    color: ${theme.color.plumMid};
    text-decoration: underline;
  }
  & p {
    color: ${theme.color.grey4};
  }
`
const TextContainer = styled.div`
  margin: 0 auto;
  text-align: left;
`
const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: ${theme.spacing.small}px;
  color: ${theme.color.red};
`
const StyledTitle3 = styled(Title3)`
  margin-bottom: ${theme.spacing.small}px;
`
const ButtonContainer = styled.div<{ $hideButton?: boolean }>`
  & > button {
    width: 100%;
    display: ${({ $hideButton }) => $hideButton && `none`};
  }

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    margin-left: auto;
  }
`
const Spacing = styled.div``
