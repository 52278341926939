import { ComponentLayout } from '../../../../components/layouts/ComponentLayout'
import styled from 'styled-components'
import { BodyLarge, Title3, Title4 } from '../../../../components/styles/Typography'
import { theme } from '../../../../theme/theme'
import { Arrow, Close, Caret } from '../../../../components/icons'
import { Stack } from '@mui/material'
import { OnClickButton } from '../../../../components/buttons/onclick/OnClickButton'
import { useNavigate } from 'react-router-dom'
import { isLowerThenCustomBreakpoint } from '../../../../utils/devices'
import { useWindowWidth } from '../../../../hooks/useWindowWidth'
import { ScreeningQuestion } from '../../../../components/ScreeningQuestion'
import { useScreeningQuestionnaireStore } from '../../../../state/store'
import { QuestionSkeleton } from '../../../../components/QuestionSkeletion'
import {
  useGetPreviousQuestionQuery,
  usePostAnswerAndGetNextQuestionMutation,
} from '../../../../api/ester-b2b-api/private/screening/screeningQueries'
import { useSelectScreening } from '../../../../state/selectors'
import { useState } from 'react'
import { Modal } from '../../../../components/Modal'

export const Questions = () => {
  const selectedScreening = useSelectScreening()
  const width = useWindowWidth()
  const isTabletOrMobile = isLowerThenCustomBreakpoint(width, 769)
  const { currentQuestion, setCurrentQuestion, resetState } = useScreeningQuestionnaireStore()
  const { mutate: postAnswerAndGetNextQuestion } = usePostAnswerAndGetNextQuestionMutation()
  const [loading, setIsLoading] = useState(false)
  const { refetch: getPreviousQuestion } = useGetPreviousQuestionQuery(selectedScreening?.id)
  const [hasError, setHasError] = useState<{
    isError: boolean
    errorMessage: string
  }>()

  const navigate = useNavigate()
  const iconSize = isTabletOrMobile ? 24 : 32
  const ArrowBack = isTabletOrMobile ? Caret : Arrow

  const handleCloseClick = () => {
    resetState() // this might not be neccesary later when we have endpoints
    navigate('/portal/halsotjanster/screening')
  }

  const handleBackClick = async () => {
    const { data: newQuestion, isSuccess, isError } = await getPreviousQuestion()
    if (isSuccess) {
      setCurrentQuestion(newQuestion)
    }
    if (isError) {
      setHasError({ isError: true, errorMessage: 'Något gick fel. Försök igen!' })
    }
  }
  const handleNextClick = () => {
    if (currentQuestion && currentQuestion.answer && selectedScreening?.id) {
      setIsLoading(true)
      postAnswerAndGetNextQuestion(
        {
          screeningId: selectedScreening.id,
          questionId: currentQuestion.id,
          answer: currentQuestion.answer?.map(({ text }) => text),
        },
        {
          onSuccess: (data) => {
            setCurrentQuestion(data)
            setIsLoading(false)
          },
          onError: () => {
            setHasError({ isError: true, errorMessage: 'Något gick fel. Försök igen!' })
            setIsLoading(false)
          },
        }
      )
    }
  }

  return (
    <>
      <HeaderContainer>
        <Section flexDirection="row" justifyContent="space-between">
          <ArrowBack left size={iconSize} onClick={handleBackClick} color={theme.color.plum} />
          <Title4>Del 1 - Frågor om dig</Title4>
          <Close size={iconSize} onClick={handleCloseClick} color={theme.color.plum} />
        </Section>
      </HeaderContainer>

      <ComponentLayout
        noMaxWidth
        paddingTop={isTabletOrMobile ? theme.spacing.xsmall : theme.spacing.xxlarge}
        style={{
          height: '100%',
        }}
      >
        <Section justifyContent="space-between">
          {currentQuestion && (
            <div>{loading ? <QuestionSkeleton /> : <ScreeningQuestion question={currentQuestion} />}</div>
          )}

          <ButtonContainer>
            <OnClickButton
              text="Nästa fråga"
              onClick={handleNextClick}
              icon={<Arrow color={theme.color.white} size={15} />}
              disabled={!currentQuestion?.answer || currentQuestion.answer.length <= 0} //TODO: check if the answer is valid
            />
          </ButtonContainer>
        </Section>
        {hasError && (
          <Modal onClose={() => setHasError(undefined)} disableClose>
            <ErrorContainer>
              <Title3>Något gick fel!</Title3>
              <ErrorBodyLarge>{hasError.errorMessage}</ErrorBodyLarge>
            </ErrorContainer>
          </Modal>
        )}
      </ComponentLayout>
    </>
  )
}

const HeaderContainer = styled.div`
  padding: ${theme.spacing.large}px ${theme.spacing.medium}px;

  & svg {
    cursor: pointer;
  }

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    border-bottom: 1px solid ${theme.color.beigeDark};
  }
`

const Section = styled(Stack)`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  height: 100%;
`

const ButtonContainer = styled.div`
  & > button {
    width: 100%;
  }

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    margin-left: auto;
  }
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  justify-content: center;
  min-width: 300px;
  text-align: center;

  h3 {
    margin-top: ${theme.spacing.medium}px;
  }
`
const ErrorBodyLarge = styled(BodyLarge)`
  margin-bottom: ${theme.spacing.large}px;
`
