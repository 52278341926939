import { ReactNode } from 'react'
import {
  QuestionType,
  QuestionOption,
  QuestionMetadata,
  UserInput,
  Question,
} from '../../api/ester-b2b-api/private/screening/types'
import { BooleanInput } from '../../components/inputs/Boolean'
import { Slider } from '../../components/inputs/Slider'
import { TextInput } from '../../components/inputs/Text'
import { TextArea } from '../../components/inputs/TextArea'
import { MultipleOptions } from '../../components/inputs/MultipleOptions'
import { MultipleOptionsSingleSelect } from '../../components/inputs/MultipleOptionsSingleSelect'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { Stack } from '@mui/material'
import { format, parse } from 'date-fns'
import { DateInput } from '../../components/inputs/DateInput'

export type ScreeningOptionsProps = {
  type: QuestionType
  questionId: string
  options?: QuestionOption[]
  metadata?: QuestionMetadata
  currentQuestion?: Question
}

type ScreeningComponentProps = ScreeningOptionsProps & {
  setAnswer: (answer: UserInput[]) => void
  answer: UserInput[]
}

type DynamicScreeningComponentsProps = Omit<ScreeningComponentProps, 'type'>

export const dynamicScreeningComponents: Partial<
  Record<QuestionType, (props: DynamicScreeningComponentsProps) => ReactNode>
> = {
  multipleOption: ({ options, setAnswer, currentQuestion }) => {
    if (!options) return null

    const currentAnswers = currentQuestion?.answer || []

    const handleSelect = ({ id, text, negative }: QuestionOption) => {
      const selectedOption = { id, text, negative }
      const isSelected = currentAnswers.some((opt) => opt.id === id)
      if (negative) {
        setAnswer(isSelected ? [] : [selectedOption])
        return
      }

      if (isSelected) {
        setAnswer(currentAnswers.filter((opt) => opt.id !== id))
      } else {
        setAnswer([...currentAnswers.filter((opt) => !opt.negative), selectedOption])
      }
    }

    return (
      <OptionsContainer>
        {options.map((option) => (
          <OptionWrapper key={option.id} $useOneColumn={options.length <= 2}>
            <MultipleOptions
              options={[
                {
                  ...option,
                  isChecked: currentAnswers.some((ans) => ans.id === option.id),
                },
              ]}
              onSelect={handleSelect}
            />
          </OptionWrapper>
        ))}
      </OptionsContainer>
    )
  },

  singleOption: ({ options, setAnswer, currentQuestion }) => {
    if (!options) return null
    const currentAnswers = currentQuestion?.answer || []

    return (
      <StyledStack maxWidth={330} spacing={1}>
        <MultipleOptionsSingleSelect
          options={options.map((option) => ({
            ...option,
            isSelected: currentAnswers.some((ans) => ans.id === option.id),
          }))}
          onSelect={(selectedOption) => setAnswer([selectedOption])}
        />
      </StyledStack>
    )
  },

  boolean: ({ options, answer, setAnswer }) => {
    if (!options) return null

    return <BooleanInput options={options} setAnswer={(newAnswer) => setAnswer(newAnswer)} answer={answer} />
  },

  info: () => {
    return <div>info</div>
  },

  scale: ({ metadata, answer, setAnswer, questionId }) => {
    if (!metadata?.scaleData) return null

    const { min, max, minLabel, maxLabel, defaultValue } = metadata.scaleData
    return (
      <Slider
        minValue={min}
        maxValue={max}
        minLabel={minLabel}
        maxLabel={maxLabel}
        defaultValue={defaultValue}
        answer={answer[0]?.text}
        onChange={(newAnswer) => setAnswer([{ id: `${questionId}_scale`, text: newAnswer }])}
      />
    )
  },

  textInput: ({ metadata, answer, setAnswer, questionId }) => {
    return (
      <TextInput
        value={answer[0]?.text || ''}
        onChange={(newAnswer) => setAnswer([{ id: `${questionId}_textInput`, text: newAnswer.target.value }])}
        placeholder={metadata?.textPlaceholder}
      />
    )
  },

  textInputMultipleLine: ({ metadata, answer, setAnswer, questionId }) => {
    return (
      <TextArea
        value={answer[0]?.text}
        onChange={(newAnswer) => setAnswer([{ id: `${questionId}_textArea`, text: newAnswer.target.value }])}
        placeholder={metadata?.textPlaceholder}
      />
    )
  },

  date: ({ metadata, answer, setAnswer, questionId }) => {
    const currentDate = answer[0]?.text ? parse(answer[0]?.text, 'yyyy-MM-dd', new Date()) : null
    return (
      <DateInput
        answer={currentDate}
        onChange={(newanswer) =>
          newanswer && setAnswer([{ id: `${questionId}_datePicker`, text: format(newanswer, 'yyyy-MM-dd') }])
        }
        granularity={metadata?.dateGranularity || ['year', 'month', 'day']}
      />
    )
  },
}

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    justify-content: center;
  }
`

const OptionWrapper = styled.div<{ $useOneColumn: boolean }>`
  width: ${({ $useOneColumn }) => ($useOneColumn ? '330px' : '250px')};
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    width: 330px;
  }
`

const StyledStack = styled(Stack)`
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    margin: 0 auto;
  }
`
