import { useAuth0 } from '@auth0/auth0-react'
import { UseMutationOptions, UseMutationResult, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { QueryKeys } from '../../types'
import { Question, Screening, ScreeningType } from './types'
import {
  getNextQuestion,
  getPreviousQuestion,
  getScreening,
  postAnswerAndGetNextQuestion,
  postStartScreening,
} from './screening'

export const useGetScreeningQuery = (): UseQueryResult<Screening, Error> => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery({
    queryKey: [QueryKeys.Screening, 'private'],
    queryFn: async (): Promise<Screening> => {
      const accessToken = await getAccessTokenSilently()
      return await getScreening({ accessToken })
    },
  })
}

export const useFetchNextQuestionMutation = (options?: UseMutationOptions<Question, Error, string>) => {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation({
    mutationFn: async (screeningId: string) => {
      const accessToken = await getAccessTokenSilently()
      return await getNextQuestion({ accessToken, screeningId })
    },
    ...options,
  })
}

export const useScreeningtMutation = (options?: UseMutationOptions<Screening, Error, string>) => {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation({
    mutationFn: async (type: ScreeningType) => {
      const accessToken = await getAccessTokenSilently()
      if (!type) throw new Error('Missing required screening type')
      return await postStartScreening({ accessToken, type })
    },
    ...options,
  })
}

export const usePostAnswerAndGetNextQuestionMutation = (
  options?: UseMutationOptions<Question, Error, { screeningId: string; questionId: string; answer: string[] }>
): UseMutationResult<Question, Error, { screeningId: string; questionId: string; answer: string[] }> => {
  const { getAccessTokenSilently } = useAuth0()
  return useMutation<Question, Error, { screeningId: string; questionId: string; answer: string[] }>({
    mutationKey: [QueryKeys.Screening, 'private', 'nextQuestion'],
    mutationFn: async ({ screeningId, questionId, answer }) => {
      const accessToken = await getAccessTokenSilently()
      return await postAnswerAndGetNextQuestion({ accessToken, screeningId, questionId, answers: answer })
    },
    ...options,
  })
}

export const useGetPreviousQuestionQuery = (screeningId?: string) => {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery({
    queryKey: [QueryKeys.Screening, 'private', 'previousQuestion', screeningId],
    queryFn: async (): Promise<Question> => {
      const accessToken = await getAccessTokenSilently()
      if (!screeningId) throw new Error('Missing required screeningId')
      return await getPreviousQuestion({ accessToken, screeningId })
    },
    //No caching
    gcTime: 0,
    staleTime: 0,
    enabled: !!screeningId,
  })
}
