import { FC } from 'react'
import { theme } from '../../theme/theme'
import styled from 'styled-components'
import { inputShadow } from '../../theme/shadows'
import { BodySmall } from '../styles/Typography'
import { Checkbox } from '../Checkbox'

type OptionButtonTypes = 'boolean' | 'singleOption' | 'multipleOptions'

interface OptionButtonProps {
  onClick: () => void
  color?: string
  backgroundColor?: string
  disabled?: boolean
  option: string
  type: OptionButtonTypes
  selected?: boolean
}

export const OptionButton: FC<OptionButtonProps> = ({
  onClick,
  color = theme.color.black,
  backgroundColor = theme.color.white,
  disabled,
  option,
  selected,
  type,
}) => {
  const isMultipleOptions = type === 'multipleOptions'

  return (
    <Button
      onClick={onClick}
      $backgroundColor={backgroundColor}
      disabled={disabled}
      $selected={selected}
      $isMultipleOptions={isMultipleOptions}
    >
      <StyledBodySmall $color={color}>{option}</StyledBodySmall>
      {isMultipleOptions && <Checkbox onChange={onClick} checked={selected} />}
    </Button>
  )
}

const Button = styled.div<{
  disabled?: boolean
  $backgroundColor: string
  $selected?: boolean
  $isMultipleOptions?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $isMultipleOptions }) => ($isMultipleOptions ? 'space-between' : 'center')};
  height: 54px;
  width: 100%;
  border: ${({ $selected }) => ($selected ? `1px solid ${theme.color.plumMid}` : `1px solid ${theme.color.grey2}`)};
  border-radius: 16px;
  background-color: ${({ disabled, $backgroundColor }) => (disabled ? theme.color.grey2 : $backgroundColor)};
  margin-right: ${theme.spacing.tiny}px;
  cursor: pointer;
  padding: 0 ${theme.spacing.medium}px;

  ${inputShadow}

  &:disabled {
    background: ${theme.color.grey2};
    border-color: ${theme.color.grey2};
    color: ${theme.color.grey3};
    cursor: not-allowed;
  }
`

const StyledBodySmall = styled(BodySmall)<{ $color: string }>`
  color: ${({ $color }) => $color};
`
