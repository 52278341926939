export enum ScreeningType {
  STANDARD = 'STANDARD',
}

export type Screening = {
  id: string
  screeningCode: string
  type: ScreeningType
  completed: boolean
}

export type QuestionOption = {
  id: string
  text: string
  negative?: boolean // If true, the option is considered negative in multiple options (e.g. "Inga besvär")
}

export type QuestionDescription = {
  text?: string
  infoText?: string
}

type granularity = 'year' | 'month' | 'day'

export type QuestionMetadata = {
  scaleData?: ScaleData
  mutliLine?: boolean
  textPlaceholder?: string
  dateGranularity?: granularity[]
}

export type QuestionType =
  | 'multipleOption'
  | 'singleOption'
  | 'scale'
  | 'textInput'
  | 'textInputMultipleLine'
  | 'date'
  | 'boolean'
  | 'info'

export type ScaleData = {
  min: number
  max: number
  minLabel: string
  maxLabel: string
  defaultValue: number
}
export type Question = {
  id: string
  previousQuestionId?: string
  type: QuestionType
  title: string
  description?: QuestionDescription
  options?: QuestionOption[]
  metadata?: QuestionMetadata
  answer?: UserInput[]
}

export type UserInput = {
  id: string
  text: string
  negative?: boolean
}

export const testQuestions: Question[] = [
  {
    id: 'test_question_date1',
    type: 'date',
    title: 'Vad är din födelsedag?',
    description: {
      text: 'Välj år, månad och dag',
    },
    metadata: {
      dateGranularity: ['year', 'month', 'day'],
    },
  },

  {
    id: 'test_question_boolean',
    type: 'boolean',
    title: 'Svara ja eller nej!',
    description: {
      text: 'Detta är en boolean fråga!',
    },
    options: [
      { id: 'boolean_yes', text: 'ja' },
      { id: 'Boolean_no', text: 'nej' },
    ],
  },
  {
    id: 'test_question_text_input',
    type: 'textInput',
    title: 'Skriv en text!',
    description: {
      text: 'Varför behöver vi veta detta?',
      infoText: 'Detta är en text input fråga!',
    },
    metadata: {
      textPlaceholder: 'Skriv här...',
    },
  },
  {
    id: 'test_question_text_input_multiple_line',
    type: 'textInputMultipleLine',
    title: 'Skriv en lite längre text text!',
    metadata: {
      textPlaceholder: 'Skriv här...',
    },
  },
  {
    id: 'test_question_slider',
    type: 'scale',
    title: 'Välj ett värde!',
    metadata: {
      scaleData: {
        min: 0,
        max: 100,
        minLabel: '0',
        maxLabel: '100',
        defaultValue: 50,
      },
    },
  },

  {
    id: 'test_question_multiple_option',
    type: 'multipleOption',
    title: 'Välj flera alternativ!',
    options: [
      { id: 'multiple_option_1', text: 'Alternativ 1' },
      { id: 'multiple_option_2', text: 'Alternativ 2' },
      { id: 'multiple_option_3', text: 'Alternativ 3' },
      { id: 'multiple_option_4', text: 'Alternativ 4', negative: true },
    ],
  },
  {
    id: 'test_question_single_option',
    type: 'singleOption',
    title: 'Välj ett alternativ!',
    options: [
      { id: 'single_option_1', text: 'Alternativ 1' },
      { id: 'single_option_2', text: 'Alternativ 2' },
      { id: 'single_option_3', text: 'Alternativ 3' },
    ],
  },
  {
    id: 'test_question_info',
    type: 'info',
    title: 'Information',
    answer: [{ id: 'test_question_info', text: 'Information read and acknowledged' }],
  },
]
