import styled from 'styled-components'
import { BodyMedium, BodyTiny, Title4 } from './styles/Typography'
import { theme } from '../theme/theme'
import { Info } from './icons'
import { Popover } from './Popover'
import { QuestionDescription } from '../api/ester-b2b-api/private/screening/types'

type Props = {
  title: string
  description?: QuestionDescription
}

export const ScreeningTitle = ({ title, description }: Props) => {
  const infoText = description?.infoText

  return (
    <Container>
      <Title4>{title}</Title4>
      {description && (
        <DescriptionWrapper $color={infoText ? theme.color.brightBlue : theme.color.black}>
          <BodyMedium>{description.text}</BodyMedium>
          {infoText && (
            <Popover text={infoText}>
              <Info size={theme.fontSize.default} color={theme.color.brightBlue} />
            </Popover>
          )}
        </DescriptionWrapper>
      )}
    </Container>
  )
}

const Container = styled.div`
  & > * {
    text-align: left;
  }
`

const DescriptionWrapper = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  color: ${({ $color }) => $color};
  gap: ${theme.spacing.tiny}px;
  & > ${BodyTiny} {
    margin-left: ${theme.spacing.tiny}px;
  }

  & > svg {
    cursor: pointer;
  }
`
