import { QuestionOption } from '../../api/ester-b2b-api/private/screening/types'
import { OptionButton } from '../buttons/OptionButton'

type Props = {
  options: Array<QuestionOption & { isChecked: boolean }>
  onSelect: (option: QuestionOption) => void
}

export const MultipleOptions = ({ options, onSelect }: Props) => {
  return options.map((option) => (
    <OptionButton
      key={option.id}
      onClick={() => onSelect(option)}
      option={option.text}
      type="multipleOptions"
      selected={option.isChecked}
    />
  ))
}
