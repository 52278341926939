import styled from 'styled-components'
import { ScreeningTitle } from './ScreeningTitle'
import { Question } from '../api/ester-b2b-api/private/screening/types'
import { theme } from '../theme/theme'
import { DynamicScreeningOptions } from './DynamicScreeningOptions'

type Props = {
  question: Question
}
export const ScreeningQuestion = ({ question }: Props) => {
  const { title, description, type, options, metadata } = question

  return (
    <div>
      <TitleContainer>
        <ScreeningTitle title={title} description={description} />
      </TitleContainer>
      <DynamicScreeningOptions type={type} questionId={question.id} options={options} metadata={metadata} />
    </div>
  )
}
const TitleContainer = styled.div`
  margin-bottom: ${theme.spacing.xxlarge}px;
`
