import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { User } from '../api/ester-b2b-api/private/users/types'
import { Services } from '@estercare/ester-shared'
import { Question, Screening, UserInput } from '../api/ester-b2b-api/private/screening/types'

type ScreeningStoreState = {
  screening: null | Screening
  setScreening: (screening: Screening) => void
  screeningResultStatus?: string
  setScreeningResultStatus: (status: string) => void
}

type ServiceStoreState = {
  activeServices: Services[]
  setActiveServices: (services: Services[]) => void
}
type ScreeningQuestionnaireStoreState = {
  currentQuestion: Question | null
  setCurrentQuestion: (question: Question) => void
  updateCurrentAnswer: (answer: UserInput[]) => void
  resetState: () => void
}

export type UserStoreState = {
  user?: User
  setUser: (user: User) => void
}

export const useUserStore = create<UserStoreState>()(
  devtools((set) => ({
    user: undefined,
    setUser: (user) => set({ user }),
  }))
)

export const useServicesStore = create<ServiceStoreState>()(
  devtools((set) => ({
    activeServices: [],
    setActiveServices: (activeServices) =>
      set({
        activeServices,
      }),
  }))
)

export const useScreeningStore = create<ScreeningStoreState>()(
  devtools((set) => ({
    screening: null,
    setScreening: (screening) => set({ screening }),
    screeningResultStatus: null,
    setScreeningResultStatus: (status: string) => set({ screeningResultStatus: status }),
  }))
)

export const useScreeningQuestionnaireStore = create<ScreeningQuestionnaireStoreState>()(
  devtools((set) => ({
    currentQuestion: null,
    currentAnswer: null,

    setCurrentQuestion: (question) => {
      set({
        currentQuestion: question,
      })
    },

    updateCurrentAnswer: (answer) => {
      set((state) => {
        if (!state.currentQuestion) return state

        const updatedQuestion = {
          ...state.currentQuestion,
          answer,
        }

        return {
          currentQuestion: updatedQuestion,
        }
      })
    },

    resetState: () =>
      set({
        currentQuestion: null,
      }),
  }))
)
