import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { BodyTiny, Label } from '../styles/Typography'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  backgroundColor?: string
  placeholder?: string
  label?: string
  error?: string
}

export const TextInput = forwardRef<HTMLInputElement, InputProps>(
  ({ backgroundColor = theme.color.white, label, error, placeholder, ...props }, ref) => {
    return (
      <InputWrapper>
        {label && <StyledLabel>{label}</StyledLabel>}
        <StyledInput
          ref={ref}
          {...props}
          $backgroundColor={backgroundColor}
          error={Boolean(error)}
          placeholder={placeholder}
        />
        {error && <StyledErrorText>{error}</StyledErrorText>}
      </InputWrapper>
    )
  }
)

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLabel = styled(Label)`
  margin-bottom: ${theme.spacing.xsmall}px;
  font-size: ${theme.fontSize.xsmall}px;
  && {
    text-transform: none;
  }
`

const StyledInput = styled.input<{ $backgroundColor: string; error: boolean }>`
  cursor: pointer;
  width: 100%;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${theme.spacing.xsmall}px;
  padding: ${theme.spacing.medium}px ${theme.spacing.xsmall}px;
  border: ${({ error }) => (error ? `1px solid ${theme.color.red}` : `1px solid transparent`)};
  font-family: ${theme.font.body};
  outline: 0;

  &:focus {
    border: 1px solid ${theme.color.plum};
  }

  &:disabled {
    cursor: not-allowed;
  }
`

const StyledErrorText = styled(BodyTiny)`
  margin-top: ${theme.spacing.tiny}px;
  color: ${theme.color.red};
`
