import {
  CalendarIcon,
  DateView,
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
  PickersLayout,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { theme } from '../../theme/theme'
import styled, { css } from 'styled-components'
import { isMobile } from '../../utils/devices'
import InputAdornment from '@mui/material/InputAdornment'
import { sv } from 'date-fns/locale'

type DateProps = {
  disabled?: boolean
  answer: Date | null
  onChange: (value: Date | null) => void
  granularity: DateView[]
}

export const DateInput = ({ answer, onChange, granularity }: DateProps) => {
  const width = window.innerWidth

  const commonProps = {
    value: answer,
    onChange: (newValue: Date | null) => onChange(newValue),
    views: granularity,
  }

  return (
    <DateContainer>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        {isMobile(width) ? (
          <StyledMobileDatePicker
            {...commonProps}
            slots={{
              layout: StyledMobilePickersLayout,
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon style={{ color: theme.color.plum }} />
                    </InputAdornment>
                  ),
                },
              },
              toolbar: {
                toolbarFormat: (() => {
                  if (granularity.includes('day')) {
                    return 'd MMMM yyyy' // When day is included, show full date
                  } else if (granularity.includes('month')) {
                    return 'MMMM yyyy' // When month is included (but not day), show month and year
                  } else {
                    return 'yyyy' // When only year is included, show just the year
                  }
                })(),
              },
            }}
          />
        ) : (
          <StyledDesktopDatePicker
            {...commonProps}
            slots={{
              layout: StyledDesktopPickersLayout,
            }}
            slotProps={{
              popper: {
                sx: {
                  '& .MuiPaper-root': {
                    boxShadow: 'none ',
                  },
                },
              },
            }}
          />
        )}
      </LocalizationProvider>
    </DateContainer>
  )
}

const DateContainer = styled.div`
  display: flex;
`

const datePickerStyles = css`
  .MuiOutlinedInput-root {
    border-radius: 6px;
    background-color: ${theme.color.white};

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.color.plumMid};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.color.plumMid};
      border-width: 2px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.color.beigeDark};
  }
`

const commonPickersLayoutStyles = css`
  .MuiPickersLayout-contentWrapper {
    background-color: ${theme.color.white};
  }

  .MuiPickersCalendarHeader-root {
    color: ${theme.color.plum};
  }

  .MuiPickersCalendarHeader-label {
    color: ${theme.color.plum};
  }

  .MuiPickersArrowSwitcher-root button {
    color: ${theme.color.plum};
  }

  && {
    .MuiPickersDay-root.Mui-selected {
      background-color: ${theme.color.plum};
      &:hover {
        background-color: ${theme.color.plumMid};
      }
    }
  }

  .MuiPickersDay-root.Mui-selected.MuiPickersDay-today {
    border: 1px solid ${theme.color.plum};
  }
  && {
    .MuiPickersMonth-root .Mui-selected {
      background-color: ${theme.color.plum};

      &:hover {
        background-color: ${theme.color.plumMid};
      }
    }
  }

  && {
    .MuiPickersYear-root .Mui-selected {
      background-color: ${theme.color.plum};

      &:hover {
        background-color: ${theme.color.plumMid};
      }
    }
  }
`

const StyledDesktopDatePicker = styled(DesktopDatePicker)`
  width: 300px;
  ${datePickerStyles}
`

const StyledMobileDatePicker = styled(MobileDatePicker)`
  width: 100%;
  justify-self: center;

  ${datePickerStyles}
`

const StyledDesktopPickersLayout = styled(PickersLayout)`
  .MuiPickersLayout-contentWrapper {
    border-radius: 6px;
    border: 2px solid ${theme.color.beigeDark};
  }
  ${commonPickersLayoutStyles}
`

const StyledMobilePickersLayout = styled(PickersLayout)`
  .MuiPickersToolbar-root {
    background-color: ${theme.color.white};
  }

  .MuiDialogActions-root {
    background-color: ${theme.color.white};

    button {
      color: ${theme.color.plumMid};
    }
  }

  ${commonPickersLayoutStyles}
`
