import { useScreeningQuestionnaireStore } from '../state/store'
import { dynamicScreeningComponents, ScreeningOptionsProps } from '../utils/screening/dynamicScreeningComponents'

export const DynamicScreeningOptions = ({ type, questionId, options, metadata }: ScreeningOptionsProps) => {
  const { currentQuestion, updateCurrentAnswer } = useScreeningQuestionnaireStore()

  const answer = currentQuestion?.id === questionId ? currentQuestion?.answer || [] : []
  const component = dynamicScreeningComponents[type]

  if (!component) {
    return null
  }
  return component({
    questionId,
    options,
    metadata,
    answer,
    setAnswer: updateCurrentAnswer,
    currentQuestion: currentQuestion || undefined,
  })
}
