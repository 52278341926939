import { Skeleton } from '@mui/material'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { isMobile } from '../utils/devices'
import { useWindowWidth } from '../hooks/useWindowWidth'

export const QuestionSkeleton = () => {
  const width = useWindowWidth()

  return (
    <SkeletonWrapper>
      <SkeletonContainer>
        <TitleSkeleton $isMobile={isMobile(width)}>
          <StyledSkeleton
            variant="rectangular"
            width={isMobile(width) ? 260 : 450}
            height={isMobile(width) ? 30 : 40}
            animation="wave"
          />
          <StyledSkeleton variant="rectangular" width={isMobile(width) ? 150 : 300} height={20} animation="wave" />
        </TitleSkeleton>
        <StyledSkeleton variant="rectangular" width={isMobile(width) ? 300 : 600} height={80} animation="wave" />
      </SkeletonContainer>
    </SkeletonWrapper>
  )
}

const SkeletonWrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${theme.breakpoint.small}px) {
    justify-content: center;
  }
`
const SkeletonContainer = styled.div`
  flex-direction: column;
`
const TitleSkeleton = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: ${({ $isMobile }) => ($isMobile ? theme.spacing.xlarge : theme.spacing.large)}px;
`
const StyledSkeleton = styled(Skeleton)`
  border-radius: 6px;
`
