import { appConfig } from '../../../../config'
import { Question, Screening, ScreeningType } from './types'

export const getScreening = async ({ accessToken }: { accessToken: string }): Promise<Screening> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/screening`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const data = await res.json()
  return data
}

export const getNextQuestion = async ({
  accessToken,
  screeningId,
}: {
  accessToken: string
  screeningId: string
}): Promise<Question> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/screening/${screeningId}/nextQuestion`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  if (!res.ok) {
    throw new Error(`Failed to fetch next question`)
  }
  const data = await res.json()
  return data
}

export const postStartScreening = async ({
  accessToken,
  type,
}: {
  accessToken: string
  type: ScreeningType
}): Promise<Screening> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/screening/start`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ type }),
  })
  if (!res.ok) {
    throw new Error(`Failed to start screening with type ${type}`)
  }
  return await res.json()
}

export const getPreviousQuestion = async ({
  accessToken,
  screeningId,
}: {
  accessToken: string
  screeningId: string
}): Promise<Question> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/screening/${screeningId}/previousQuestion`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (!res.ok) {
    throw new Error(`Failed to fetch previous question`)
  }
  return await res.json()
}

export const postAnswerAndGetNextQuestion = async ({
  accessToken,
  screeningId,
  questionId,
  answers,
}: {
  accessToken: string
  screeningId: string
  questionId: string
  answers: string[]
}): Promise<Question> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/screening/${screeningId}/answer`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ questionId, answers }),
  })
  if (!res.ok) {
    throw new Error(`Failed to post answer and get next question`)
  }
  return await res.json()
}
