import { createTheme } from '@mui/material'
import { theme } from '../../theme/theme'
import { svSE } from '@mui/x-date-pickers/locales'

export const muiTheme = createTheme(
  {
    typography: {
      fontFamily: theme.font.body,
    },
    palette: {
      background: {
        default: theme.color.beigeLight,
        paper: theme.color.beigeLight,
      },
    },
    components: {
      MuiPopover: {
        styleOverrides: {
          root: {
            zIndex: 10000,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderColor: 'transparent',
            borderRadius: `${theme.spacing.xsmall}px`,
            fontSize: `${theme.fontSize.xsmall}px`,
            height: '50px',
          },
        },
      },
    },
  },
  svSE
)
