import styled from 'styled-components'
import { OptionButton } from '../buttons/OptionButton'
import { theme } from '../../theme/theme'
import { UserInput } from '../../api/ester-b2b-api/private/screening/types'

type BooleanInputProps = {
  options: { id: string; text: string }[]
  answer: UserInput[]
  setAnswer: (option: UserInput[]) => void
  color?: string
  backgroundColor?: string
}

export const BooleanInput = ({ options, color, backgroundColor, answer, setAnswer }: BooleanInputProps) => {
  return (
    <BooleanContainer>
      {options.map((option, index) => (
        <OptionButton
          key={index}
          onClick={() => setAnswer([{ id: option.id, text: option.text }])}
          option={option.text}
          type="boolean"
          backgroundColor={backgroundColor}
          color={color}
          selected={answer[0] && answer[0].id === option.id}
        />
      ))}
    </BooleanContainer>
  )
}

const BooleanContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xsmall}px;
`
