import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { ConsentId } from './types'
import { postConsent } from './consent'

export const useConsentMutation = (options?: UseMutationOptions<Response, Error, ConsentId>) => {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation({
    mutationFn: async (consentId?: ConsentId) => {
      const accessToken = await getAccessTokenSilently()
      if (!consentId) throw new Error('Missing required consentId')
      return await postConsent({ accessToken, consentId })
    },
    ...options,
  })
}
