import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { BodySmall, Label } from '../styles/Typography'

interface InputProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  backgroundColor?: string
  label?: string
  error?: string
  placeholder?: string
}

export const TextArea = forwardRef<HTMLTextAreaElement, InputProps>(
  ({ backgroundColor = theme.color.white, label, error, placeholder, ...props }, ref) => {
    return (
      <InputWrapper>
        {label && <StyledLabel>{label}</StyledLabel>}
        <StyledInput ref={ref} {...props} $backgroundColor={backgroundColor} placeholder={placeholder} />
        {error && <StyledErrorText>{error}</StyledErrorText>}
      </InputWrapper>
    )
  }
)

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLabel = styled(Label)`
  margin-bottom: ${theme.spacing.xsmall}px;
  font-size: ${theme.fontSize.xsmall}px;
  && {
    text-transform: none;
  }
`

const StyledInput = styled.textarea<{ $backgroundColor: string }>`
  cursor: pointer;
  width: 100%;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${theme.spacing.xsmall}px;
  padding: ${theme.spacing.medium}px ${theme.spacing.xsmall}px;
  border: 1px solid transparent;
  font-family: ${theme.font.body};
  outline: 0;

  &:focus {
    border: 1px solid ${theme.color.plum};
  }

  &:disabled {
    cursor: not-allowed;
  }
`

const StyledErrorText = styled(BodySmall)`
  color: ${theme.color.red};
`
