import styled from 'styled-components'
import { ChangeEvent, InputHTMLAttributes } from 'react'
import { theme } from '../theme/theme'
import { Label } from './styles/Typography'

type Props = {
  label?: string
  backgroundColor?: string
  borderColor?: string
  checkmarkColor?: string
  fontSize?: keyof typeof theme.fontSize
  onChange?: (checked: boolean | undefined, event: ChangeEvent<HTMLInputElement>) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>

export const Checkbox = ({
  label,
  disabled,
  className,
  backgroundColor,
  borderColor,
  checkmarkColor,
  fontSize,
  checked,
  onChange,
  ...props
}: Props) => {
  return (
    <CheckboxContainer data-disabled={disabled} className={className}>
      <CheckboxInput
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={(e) => onChange && onChange(checked, e)}
        {...props}
      />
      <CheckboxBox
        $disabled={disabled}
        $backgroundColor={backgroundColor}
        $borderColor={borderColor}
        $checkmarkColor={checkmarkColor}
        $checked={checked}
      />
      {label && (
        <CheckboxLabel $fontSize={fontSize} $disabled={disabled}>
          {label}
        </CheckboxLabel>
      )}
    </CheckboxContainer>
  )
}

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.small}px;
  cursor: pointer;
  position: relative;

  &[data-disabled='true'] {
    cursor: not-allowed;
  }
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const CheckboxBox = styled.div<{
  $disabled?: boolean
  $backgroundColor?: string
  $borderColor?: string
  $checkmarkColor?: string
  $checked?: boolean
}>`
  width: ${theme.spacing.mediumLarge}px;
  height: ${theme.spacing.mediumLarge}px;
  border: 2px solid ${({ $borderColor }) => $borderColor || theme.color.grey2};
  border-radius: ${theme.spacing.tiny}px;
  background-color: ${({ $backgroundColor }) => $backgroundColor || 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    display: ${(props) => (props.$checked ? 'block' : 'none')};
    width: 6px;
    height: ${theme.spacing.small}px;
    border: solid ${({ $checkmarkColor }) => $checkmarkColor || theme.color.plumMid};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

const CheckboxLabel = styled(Label)<{
  $fontSize?: keyof typeof theme.fontSize
  $disabled?: boolean
}>`
  && {
    text-transform: none;
    font-size: ${({ $fontSize }) => ($fontSize ? theme.fontSize[$fontSize] : theme.fontSize.default)}px;
    font-weight: 400;
    color: ${({ $disabled }) => ($disabled ? theme.color.grey3 : 'inherit')};
  }
`
