/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import styled from 'styled-components'
import { Title1, Title6 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Grid } from '../../components/Grid'
import { TextInput } from '../../components/inputs/Text'
import { TextArea } from '../../components/inputs/TextArea'
import { Radios } from '../../components/inputs/Radios'
import { SelectInput } from '../../components/inputs/Select'
import { Divider } from '@mui/material'
import { BooleanInput } from '../../components/inputs/Boolean'
import { Slider } from '../../components/inputs/Slider'
import { MultipleOptions } from '../../components/inputs/MultipleOptions'
import { MultipleOptionsSingleSelect } from '../../components/inputs/MultipleOptionsSingleSelect'
import { QuestionOption } from '../../api/ester-b2b-api/private/screening/types'
import { DateInput } from '../../components/inputs/DateInput'
import { QuestionSkeleton } from '../../components/QuestionSkeletion'

const OptionsMockData = [
  {
    id: '1',
    text: 'Option 1',
    negative: false,
  },
  {
    id: '2',
    text: 'Option 2',
    negative: false,
  },
  {
    id: '3',
    text: 'Option 3',
    negative: false,
  },
  {
    id: 'negative',
    text: 'Negative',
    negative: true,
  },
]

export const Inputs = () => {
  const [textStandard, setTextStandard] = useState('This is a standard valid text input')
  const [textDisabled, setTextDisabled] = useState('This is a disabled text input')
  const [textInvalid, setTextInvalid] = useState('This is an invalid text input')
  const [radioOption, setRadioOption] = useState('Option 1')
  const [selectOption, setSelectOption] = useState('Option 1')
  const [selectedMultipleOptions, setSelectedMultipleOptions] = useState<QuestionOption[]>([OptionsMockData[0]!])
  const [selectedMultipleOptionsSingleSelect, setSelectedMultipleOptionsSingleSelect] = useState({
    id: OptionsMockData[0]!.id,
    text: OptionsMockData[0]!.text,
  })

  const MultipleOptionsOnchange = ({ id, text, negative }: QuestionOption) => {
    const selectedOption = { id, text, negative }
    const isSelected = selectedMultipleOptions.some((opt) => opt.id === id)

    if (negative) {
      setSelectedMultipleOptions(isSelected ? [] : [selectedOption])
      return
    }

    if (isSelected) {
      setSelectedMultipleOptions((prevOptions) => prevOptions.filter((opt) => opt.id !== id))
    } else {
      setSelectedMultipleOptions((prevOptions) => [...prevOptions.filter((opt) => !opt.negative), selectedOption])
    }
  }

  return (
    <ComponentLayout>
      <QuestionSkeleton />
      <StyledTitle1>Inputs</StyledTitle1>
      <StyledTitle6>Text input</StyledTitle6>
      <StyledGrid $columns={2}>
        <TextInput label="Standard" value={textStandard} onChange={(e) => setTextStandard(e.target.value)} />
        <TextInput label="Disabled" value={textDisabled} onChange={(e) => setTextDisabled(e.target.value)} disabled />
        <TextInput
          label="Invalid"
          value={textInvalid}
          onChange={(e) => setTextInvalid(e.target.value)}
          error="You filled in something wrong"
        />
      </StyledGrid>
      <Divider />
      <StyledTitle6>Text area input</StyledTitle6>
      <StyledGrid $columns={2}>
        <TextArea label="Standard" />
        <TextArea label="Disabled" disabled />
      </StyledGrid>
      <Divider />
      <StyledTitle6>Select input</StyledTitle6>
      <StyledGrid $columns={2}>
        <SelectInput
          placeholder="Select an option"
          options={[
            { label: 'Option 1', value: '1' },
            { label: 'Option 2', value: '2' },
            { label: 'Option 3', value: '3' },
          ]}
          value={selectOption}
          onChange={setSelectOption}
        />
      </StyledGrid>
      <Divider />
      <StyledTitle6>Boolean input</StyledTitle6>
      <StyledGrid $columns={2}>
        <BooleanInput
          options={[
            { id: 'yes', text: 'Ja' },
            { id: 'no', text: 'Nej' },
          ]}
          answer={[]}
          setAnswer={() => {}}
        />
      </StyledGrid>
      <Divider />
      <StyledTitle6>Radios</StyledTitle6>
      <StyledGrid $columns={2}>
        <Radios
          defaultValue={radioOption}
          options={['Option 1', 'Option 2', 'Option 3']}
          setChange={setRadioOption}
          component="component"
        />
      </StyledGrid>
      <Divider />
      <StyledTitle6>Slider</StyledTitle6>

      <StyledGrid $columns={2}>
        <Slider
          minValue={0}
          maxValue={100}
          minLabel="0 - Min"
          maxLabel="100 - Max"
          disabled={false}
          onChange={() => {}}
        />
        <Slider
          minValue={0}
          maxValue={10}
          minLabel="disabled"
          maxLabel="disabled"
          disabled={true}
          onChange={() => {}}
        />
      </StyledGrid>
      <Divider />
      <StyledTitle6>Input with Checkboxes</StyledTitle6>

      <StyledGrid $columns={4}>
        <MultipleOptions
          onSelect={MultipleOptionsOnchange}
          options={OptionsMockData.map((option) => ({
            ...option,
            isChecked: selectedMultipleOptions.some((opt) => opt.id === option.id),
          }))}
        />
      </StyledGrid>
      <Divider />
      <StyledTitle6>Multiple options single select </StyledTitle6>
      <StyledGrid $columns={2}>
        <MultipleOptionsSingleSelect
          onSelect={(selectedVal) =>
            setSelectedMultipleOptionsSingleSelect({
              id: selectedVal.id,
              text: selectedVal.text,
            })
          }
          options={OptionsMockData.slice(0, 3).map(({ id, text }) => {
            return {
              id,
              text,
              isSelected: selectedMultipleOptionsSingleSelect.id === id,
            }
          })}
        />
      </StyledGrid>
      <Divider />
      <StyledTitle6>Date inputs </StyledTitle6>
      <StyledGrid $columns={4}>
        <DateInput answer={null} onChange={() => {}} granularity={['day', 'month', 'year']} />
        <DateInput answer={null} onChange={() => {}} granularity={['month', 'year']} />
        <DateInput answer={null} onChange={() => {}} granularity={['year']} />
      </StyledGrid>
    </ComponentLayout>
  )
}

const StyledTitle1 = styled(Title1)`
  margin-bottom: ${theme.spacing.large}px;
`
const StyledTitle6 = styled(Title6)`
  margin-top: ${theme.spacing.medium}px;
  margin-bottom: ${theme.spacing.medium}px;
  text-transform: uppercase;
`
const StyledGrid = styled(Grid)`
  gap: ${theme.spacing.large}px;
  margin-bottom: ${theme.spacing.large}px;
`
