import { appConfig } from '../../../../config'
import { ConsentId } from './types'

export const postConsent = async ({
  consentId,
  accessToken,
}: {
  consentId: ConsentId
  accessToken: string
}): Promise<Response> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/consent`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ consentId }),
  })

  if (!res.ok) {
    throw new Error(`Failed to post consent: ${consentId}`)
  }
  return res
}
